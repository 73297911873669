import { Autocomplete, TextField } from '@mui/material';
import { RestApiClient } from '@shahadul-17/rest-api-client';
import { useState } from 'react';
import { useInitiator } from '../../hooks';

const getSelectedFilesAsArray = selectedFiles => {
  const _selectedFiles = [];

  for (let i = 0; i < selectedFiles.length; i++) {
    const selectedFile = selectedFiles[i];

    if (!selectedFile) { continue; }

    _selectedFiles.push(selectedFile);
  }

  return _selectedFiles;
};

export const FileUpload = ({ fileInfo, onBeforeUpload, onUpload, onCancel, }) => {
  const [mode, setMode] = useState(fileInfo ? 'UPDATE' : 'UPLOAD');
  const [files, setFiles] = useState([]);
  const [_fileInfo, setFileInfo] = useState(fileInfo ?? undefined);
  const [tags, setTags] = useState([]);
  const [keywords, setKeywords] = useState([]);

  const onFileSelectedAsync = async event => {
    const _files = getSelectedFilesAsArray(event.target.files);

    setFiles(_files);
  };

  const onCancelButtonClickedAsync = async event => {
    if (typeof onCancel !== 'function') { return; }

    await onCancel({
      mode: mode,
      fileInfo: _fileInfo,
    });
  };

  const onFormSubmittedAsync = async event => {
    event.preventDefault();

    const formDataFileKey = mode === 'UPDATE' ? 'file' : 'files';
    const formData = new FormData();
    tags.length && formData.append('tags', tags.join(','));
    keywords.length && formData.append('keywords', keywords.join(','));
    _fileInfo?.fileId && formData.append('fileId', _fileInfo.fileId);

    for (const file of files) {
      formData.append(formDataFileKey, file);

      if (mode === 'UPDATE') { break; }
    }

    typeof onBeforeUpload === 'function' && await onBeforeUpload({
      mode: mode,
      tags: tags,
      keywords: keywords,
      files: files,
      oldFileInfo: _fileInfo,
    });

    const restApiClient = RestApiClient.getInstance();
    const response = await restApiClient.sendSmartRequestAsync({
      routeName: _fileInfo?.fileId ? 'updateFile' : 'uploadFiles',
      data: formData,
    });

    const fileInput = document.getElementById('fileInput');
    fileInput.value = null;

    setTags([]);
    setKeywords([]);

    if (typeof onUpload !== 'function') { return; }

    await onUpload({
      mode: mode,
      tags: tags,
      keywords: keywords,
      files: files,
      oldFileInfo: _fileInfo,
      response,
    });
  };

  useInitiator(async () => {
    setMode(fileInfo ? 'UPDATE' : 'UPLOAD');

    if (!fileInfo) {
      setFileInfo(undefined);
      setTags([]);
      setKeywords([]);

      return;
    }

    setFileInfo(fileInfo);
    Array.isArray(fileInfo.tags) && setTags(fileInfo.tags);
    Array.isArray(fileInfo.keywords) && setKeywords(fileInfo.keywords);
  }, [mode, fileInfo]);

  return <div>
    <p>{mode === 'UPDATE' ? 'Update File' : 'Upload Files'}</p>
    <p>{mode === 'UPDATE' ? `File ID: ${_fileInfo?.fileId ?? ''}` : ''}</p>
    <form onSubmit={onFormSubmittedAsync}>
      <Autocomplete
        size='small'
        freeSolo={true}
        value={tags}
        options={[]}
        multiple={true}
        autoComplete={false}
        onChange={async (_, value) => setTags(value)}
        renderInput={params => <TextField {...params} label={'Tags'} />} />
      <Autocomplete
        size='small'
        freeSolo={true}
        value={keywords}
        options={[]}
        multiple={true}
        autoComplete={false}
        onChange={async (_, value) => setKeywords(value)}
        renderInput={params => <TextField {...params} label={'Keywords'} />} />
      <input id='fileInput' type="file" onChange={onFileSelectedAsync} multiple={mode === 'UPLOAD'} />
      <br />
      <button type='submit'>{mode === 'UPDATE' ? 'Update' : 'Upload'}</button>
      {mode === 'UPDATE' && <button type='button' onClick={onCancelButtonClickedAsync}>Cancel</button>}
    </form>
  </div>;
};
