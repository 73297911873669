import { RestApiClient, } from '@shahadul-17/rest-api-client';
import { useEffect, useState } from 'react';
import { ArrayUtilities } from '../../utilities/array-utilities';
import Styles from "./ImageUpload.module.scss";
import CloseIcon from '@mui/icons-material/Close';

const ImagePreview = ({ type, image, onRemove, }) => {

  const onRemoveButtonClickedAsync = async event => {
    if (typeof onRemove !== 'function') { return; }

    await onRemove({ type, image, event, });
  };

  return <div className={Styles.container}>
    <CloseIcon className={Styles.icon} onClick={onRemoveButtonClickedAsync} />
    {type === 'LOCAL' && image && <img className={Styles.img} src={URL.createObjectURL(image)} />}
    {type === 'REMOTE' && image && <img className={Styles.img} src={image.url} />}
    {/* <button  type='button' onClick={onRemoveButtonClickedAsync}>Remove Image</button> */}

  </div>;
};

export const ImageUpload = ({ propertyImages, onUpload, onRemoteImageRemove, }) => {
  let fileInput = undefined;
  const [localImages, setLocalImages] = useState([]);
  const [remoteImages, setRemoteImages] = useState(propertyImages ?? []);

  // this method is called when change button is clicked.
  // it actually opens file selection dialog...
  const onBrowseButtonClickedAsync = async event => {
    if (!fileInput) { return; }

    fileInput.click();
  };

  const uploadAsync = async (localImages) => {
    if (!localImages.length) { return; }

    const formData = new FormData();
    formData.append('tags', 'PRODUCT IMAGE');

    for (const localImage of localImages) {
      formData.append('files', localImage);
    }

    const restApiClient = RestApiClient.getInstance();
    const response = await restApiClient.sendSmartRequestAsync({
      routeName: 'uploadFiles',
      data: formData,
    });

    if (typeof onUpload === 'function') {
      await onUpload({ images: localImages, response, });

      response.status === 200 && setLocalImages([]);
    }
  };

  const getSelectedFilesAsArray = selectedFiles => {
    const _selectedFiles = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      const selectedFile = selectedFiles[i];

      if (!selectedFile) { continue; }

      _selectedFiles.push(selectedFile);
    }

    return _selectedFiles;
  };

  const onImageChangedAsync = async event => {
    const selectedFiles = getSelectedFilesAsArray(event.target.files);
    const _localImages = ArrayUtilities.addElements(selectedFiles, localImages);

    // sets selected files to state...
    // setLocalImages(_localImages);

    event.target.value = '';

    await uploadAsync(_localImages);
  };

  const onImageRemovedAsync = async ({ type, image, event, }) => {
    if (type === 'LOCAL') {
      const _localImages = ArrayUtilities.remove(undefined, image, localImages);

      setLocalImages(_localImages);
    } else if (type === 'REMOTE') {
      // remote image delete shall be handled properly...
      // user may not click save after deleting a remote image...
      // so the reference will remain in the products inventory even though the file has been deleted from storage...

      /* const restApiClient = RestApiClient.getInstance();
      const response = await restApiClient.sendSmartRequestAsync({
        routeName: 'deleteFile',
        data: { fileId: image.fileId, },
      });

      if (response.status !== 200) { return; } */

      const _remoteImages = ArrayUtilities.remove('fileId', image.fileId, remoteImages);

      setRemoteImages(_remoteImages);

      if (typeof onRemoteImageRemove === 'function') {
        await onRemoteImageRemove({ type, image, remoteImages: _remoteImages, event, });
      }
    }
  };

  useEffect(() => {
    if (!Array.isArray(propertyImages)) { return; }

    setRemoteImages(propertyImages);
  }, [propertyImages]);

  return <>
    {/* This div is used to preview images. */}
    <div className={Styles.imgContainer}>
      {remoteImages.map((remoteImage, index) => {
        return <ImagePreview key={index} image={remoteImage} type='REMOTE' onRemove={onImageRemovedAsync} />
      })}
      {localImages.map((localImage, index) => {
        return <ImagePreview key={index} image={localImage} type='LOCAL' onRemove={onImageRemovedAsync} />
      })}
    </div>

    {/* This div holds the controls (e.g. upload button). */}
    <div className={Styles.imgUploadBtn}>
      <input type="file"
        style={{ display: 'none', }}
        ref={reference => fileInput = reference}
        accept="image/*"
        multiple={true}
        onChange={onImageChangedAsync} />
      <button type='button' onClick={onBrowseButtonClickedAsync}>Browse image</button>
      {/* <button type='button' onClick={uploadAsync}>Upload</button> */}
    </div>
  </>;
};
