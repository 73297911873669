import { useState } from 'react';
import { useInitiator } from '../../hooks';

import styles from './IndeterminateProgressBar.module.css';

const DEFAULT_HIDE_TIMEOUT_IN_MILLISECONDS = 500;

export const IndeterminateProgressBar = ({ isVisible, }) => {
  const [_isVisible, setVisible] = useState(isVisible ?? true);

  useInitiator(async () => {
    // checks if 'isVisible' is false...
    if (isVisible === false) {
      setTimeout(() => {
        setVisible(false);
      }, DEFAULT_HIDE_TIMEOUT_IN_MILLISECONDS);

      return;
    }

    // if 'isVisible' is true, sets immediately...
    setVisible(isVisible);
  }, [isVisible]);

  return <>
    <div className={styles.indeterminateProgressBar}>
      {_isVisible && <div />}
    </div>
  </>;
};
