import { Suspense } from 'react';
import { Route, Routes } from 'react-router';
import { EmptyComponent, secure } from '..';
import { Spinner } from '../Spinner';

export const renderRoutes = routes => {
  // if routes is not an array, we shall return empty fragment...
  if (!Array.isArray(routes)) { return <></>; }

  // otherwise, we shall render the routes...
  return routes.map((route, index) => {
    const { isIndex, path, routes, component, } = route;
    // if component is not provided, an empty component shall be placed...
    const Component = component ?? EmptyComponent;

    return <Route
      key={index}
      index={isIndex}
      path={path}
      element={<Suspense fallback={<Spinner />}>
        {<Component routes={routes} {...route} />}
      </Suspense>}>
      {renderRoutes(routes)}
    </Route>;
  });
};

export const ExtendedSwitch = ({ routes, }) => {
  return <Routes>
    {renderRoutes(routes)}
  </Routes>;
};
